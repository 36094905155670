var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4 py-4" },
    [
      _c("div", { staticClass: "tab" }, [
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer active font-medium w-auto",
            on: { click: (e) => _vm.openTab(e, "dadosCadastrais") },
          },
          [_vm._v("\n      Dados Cadastrais\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: { click: (e) => _vm.openTab(e, "dadosComplementares") },
          },
          [_vm._v("\n      Dados Complementares\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: { click: (e) => _vm.openTab(e, "nomefantasia") },
          },
          [
            _vm._v(
              "\n       " +
                _vm._s(
                  _vm.segurado.Entity === "J" ? "Nome Fantasia" : "Nome Social"
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "tabContent active", attrs: { id: "dadosCadastrais" } },
        [
          _vm.perfilHelpers.checkPermission([
            this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS,
          ])
            ? _c(
                "div",
                { staticClass: "flex pt-5" },
                [
                  _c("label", { staticClass: "pr-2" }, [
                    _vm._v(" Bloquear segurado "),
                  ]),
                  _c(
                    "vx-tooltip",
                    {
                      staticClass: "enable-ccg",
                      attrs: {
                        text: "Ao ativar essa opção, o sugurado será bloqueado",
                        position: "right",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("info"),
                      ]),
                    ]
                  ),
                  _c(
                    "vs-switch",
                    {
                      staticClass: "ml-2",
                      attrs: { value: _vm.seguradoDetail.IsBlocked },
                      on: { input: _vm.openModalBlock },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("Sim"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("Não"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "vx-row mt-6" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  label: "Razão Social",
                  disabled: "true",
                  value: _vm.seguradoDetail.Name,
                  icon: "mode_edit",
                  size: "large",
                },
                on: {
                  "icon-click": function ($event) {
                    return _vm.openModalUpdateInsuredName(
                      _vm.seguradoDetail.Name
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-row mt-6" },
            [
              _c("vs-input", {
                staticClass: "w-1/3 pr-3",
                attrs: {
                  label: "Documento",
                  disabled: "true",
                  value: _vm.$utils.maskDocument(_vm.seguradoDetail.CpfCnpj),
                  size: "large",
                },
              }),
              _c("vs-input", {
                staticClass: "w-1/3 pr-3",
                attrs: {
                  label: "Ramo",
                  disabled: "true",
                  value: _vm.seguradoDetail.IsPrivate ? "Privado" : "Publico",
                  icon: "mode_edit",
                  size: "large",
                },
                on: {
                  "icon-click": function ($event) {
                    return _vm.openModalUpdateInsuredBranch(
                      _vm.seguradoDetail.IsPrivate
                    )
                  },
                },
              }),
              _c("vs-input", {
                staticClass: "w-1/3 pl-3",
                attrs: {
                  label: "Data de Registro",
                  disabled: "true",
                  value: _vm._f("dateFormat")(
                    new Date((_vm.seguradoDetail || {}).CreateDate),
                    "DD/MM/YYYY"
                  ),
                  size: "large",
                },
              }),
              _vm.seguradoDetail.IsBlocked
                ? _c("vs-input", {
                    staticClass: "w-1/3 pl-3",
                    attrs: {
                      label: "Data de bloqueio",
                      disabled: "true",
                      value: _vm._f("dateFormat")(
                        new Date((_vm.seguradoDetail || {}).BlockDate),
                        "DD/MM/YYYY"
                      ),
                      size: "large",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.seguradoDetail.IsBlocked
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col pt-5 w-full mt-2" },
                  [
                    _c("label", [_vm._v("Justificativa de bloqueio")]),
                    _c("vs-textarea", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.seguradoDetail.BlockJustify,
                        callback: function ($$v) {
                          _vm.$set(_vm.seguradoDetail, "BlockJustify", $$v)
                        },
                        expression: "seguradoDetail.BlockJustify",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "tabContent", attrs: { id: "dadosComplementares" } },
        [
          _c("list-endereco", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.enderecos && _vm.enderecos.length,
                expression: "enderecos && enderecos.length",
              },
            ],
            attrs: {
              enderecos: _vm.enderecos,
              podeAlterar: _vm.perfilHelpers.checkPermission([
                this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS,
              ]),
            },
            on: {
              remove: _vm.removeEndereco,
              update: _vm.atualizarEndereco,
              save: _vm.salvarEndereco,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabContent", attrs: { id: "nomefantasia" } },
        [
          _vm.segurado
            ? _c("FantasySegurado", { attrs: { segurado: _vm.segurado } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: _vm.modalTitle, active: _vm.showModalConfirm },
          on: {
            "update:active": function ($event) {
              _vm.showModalConfirm = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        return _vm.closeModalConfirm()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.confirmModalConfirm()
                      },
                    },
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: `Atualizar ${(_vm.paramModalUpdate || {}).title}`,
            active: _vm.showModalUpdate,
          },
          on: {
            "update:active": function ($event) {
              _vm.showModalUpdate = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vw-row" },
            [
              _vm.typeModalUpdate === "updateName"
                ? _c("vs-input", {
                    staticClass: "vx-col w-full mb-3",
                    attrs: {
                      label: (_vm.paramModalUpdate || {}).label,
                      size: "large",
                    },
                    model: {
                      value: (_vm.paramModalUpdate || {}).payload,
                      callback: function ($$v) {
                        _vm.$set(_vm.paramModalUpdate || {}, "payload", $$v)
                      },
                      expression: "(paramModalUpdate || {}).payload",
                    },
                  })
                : _c(
                    "el-select",
                    {
                      staticClass: "large vx-col w-full mb-3 mx-1",
                      attrs: {
                        placeholder: (_vm.paramModalUpdate || {}).label,
                      },
                      model: {
                        value: (_vm.paramModalUpdate || {}).payload,
                        callback: function ($$v) {
                          _vm.$set(_vm.paramModalUpdate || {}, "payload", $$v)
                        },
                        expression: "(paramModalUpdate || {}).payload",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Público", value: false },
                      }),
                      _c("el-option", {
                        attrs: { label: "Privado", value: true },
                      }),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "vx-col w-full text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-5",
                      on: {
                        click: function ($event) {
                          _vm.showModalUpdate = false
                        },
                      },
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-primary",
                      attrs: {
                        disabled:
                          (_vm.paramModalUpdate || {}).payload == null ||
                          (_vm.paramModalUpdate || {}).payload == undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setModalUpdate()
                        },
                      },
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "Bloquear segurado", active: _vm.showModalBlock },
          on: {
            "update:active": function ($event) {
              _vm.showModalBlock = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              [
                _c("label", { staticClass: "font-semibold" }, [
                  _vm._v("Justificativa"),
                ]),
                _c("vs-textarea", {
                  model: {
                    value: _vm.justifyBlock,
                    callback: function ($$v) {
                      _vm.justifyBlock = $$v
                    },
                    expression: "justifyBlock",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        return _vm.closeModalBlock()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    attrs: { disabled: !_vm.justifyBlock },
                    on: {
                      click: function ($event) {
                        return _vm.openModalConfirmBlock()
                      },
                    },
                  },
                  [_vm._v("\n          Bloquear segurado\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }